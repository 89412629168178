import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  TextField,
  Grid,
  Rating,
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'next-i18next';
import { FormErrors, DetailedRating } from 'views/common/components';

const PREFIX = 'ReviewOverlay';

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  closeBtn: `${PREFIX}-closeBtn`,
  bold: `${PREFIX}-bold`,
  dialogBtns: `${PREFIX}-dialogBtns`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    '&.MuiDialogContent-root': {
      [theme.breakpoints.up('md')]: {
        minWidth: theme.typography.pxToRem(600),
      },
    },
  },

  [`& .${classes.closeBtn}`]: {
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },

  [`& .${classes.bold}`]: {
    fontWeight: '500',
  },

  [`& .${classes.dialogBtns}`]: {
    '&.MuiDialogActions-root': { padding: theme.spacing(1, 3, 2.5) },
  },
}));

const ReviewOverlay = (props) => {
  const { title, reviewOverlayData } = props;
  const {
    data,
    ratingLable,
    onDataChange,
    submitReviewBtn,
    reviewError,
    state,
    rateValue,
  } = reviewOverlayData;

  const { t } = useTranslation('fe_er_my_review');
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledDialog
      open={state.open}
      onClose={state.handleClose}
      maxWidth="md"
      fullScreen={smallScreen}>
      <DialogContent className={classes.dialogContent}>
        <Box mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h3" className={classes.bold}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <IconButton
                onClick={state.handleClose}
                aria-label="Cancel"
                color="secondary"
                className={classes.closeBtn}
                size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {!rateValue ? (
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Typography component="legend" className={classes.bold}>
                  {ratingLable}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} align={!smallScreen && 'right'}>
                <Rating
                  name="star_rating"
                  value={data?.star_rating || 0}
                  onChange={(e) => onDataChange(e)}
                  size="large"
                  emptyIcon={
                    <StarRateIcon fontSize="medium" style={{ padding: 2 }} />
                  }
                />
                {reviewError?.errors && (
                  <FormErrors
                    errorMsgs={reviewError}
                    errorField="star_rating"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box mb={1} mt={3}>
            <DetailedRating
              rateValue={rateValue}
              className={classes.reviewStars}
              onDataChange={onDataChange}
            />
          </Box>
        )}

        <Box mb={2}>
          <TextField
            type="text"
            fullWidth
            color="secondary"
            id="title"
            name="title"
            label={t('fe_er_my_review:review_title')}
            variant="outlined"
            value={data?.title || ''}
            onChange={(e) => onDataChange(e)}
          />
          {reviewError?.errors && (
            <FormErrors errorMsgs={reviewError} errorField="title" />
          )}
        </Box>

        <Box mb={2}>
          <TextField
            name="body"
            label={t('fe_er_my_review:type_review_here')}
            color="secondary"
            fullWidth
            multiline
            variant="outlined"
            rows={5}
            required
            value={data?.body || ''}
            onChange={(e) => onDataChange(e)}
          />
          {reviewError?.errors && (
            <FormErrors errorMsgs={reviewError} errorField="body" />
          )}
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogBtns}>
        <Button onClick={state.handleClose} color="primary">
          {t('fe_er_my_review:cancel')}
        </Button>

        <Button
          onClick={submitReviewBtn.action}
          color="primary"
          variant="contained"
          disableElevation>
          {submitReviewBtn.label}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ReviewOverlay;
